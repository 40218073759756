import React from "react";
import { FormError, FormField } from "../globalStyles";
import { InputLabel } from "../input/input-styles";
import { TextAreaEle } from "./textArea-styles";

const TextArea = ({ label, id, name, placeholder, value, rows, onChange, isRequired, error }) => {
  return (
    <>
      <FormField>
        <InputLabel htmlFor={id}>
          {label}
          {isRequired && <span className="required">*</span>}
        </InputLabel>
        <TextAreaEle id={id} name={name} value={value} rows={rows} placeholder={placeholder} onChange={onChange} />
        {error && <FormError>{error}</FormError>}
      </FormField>
    </>
  );
};

export default TextArea;
