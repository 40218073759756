import React, { lazy, useEffect, useRef, useState } from "react";
import { FormFields } from "../../components/globalStyles";
import Input from "../../components/input/input";
import { ContactModalBody, ContactModalContainer, ContactModalFooter, ContactModalHeader, ContactModalWrapper } from "./contact-modal-styles";
import TextArea from "../../components/textArea/textArea";

import CloseIcon from "./../../assets/icons/close.svg";
import { globalDataValues, countryCodeOptions } from "../../helpers/data";
import Select from "../../components/select/select";
const Button = lazy(() =>
  import("./../../components/button/button").then((module) => ({
    default: module.Button,
  }))
);

const { default: Mailgun } = await import("mailgun.js");
const { default: formData } = await import("form-data");
const mailgun = new Mailgun(formData);
const mg = mailgun.client({ 
  username: globalDataValues.mailgun.username, 
  key: globalDataValues.mailgun.key 
});

const ContactModal = ({ openModal, closeModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+1",
    company: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showFormMessage, setShowFormMessage] = useState(false);

  const inputref = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else {
      const phoneRegex = /^\d{6,15}$/;
      if (!phoneRegex.test(formData.phone)) {
        newErrors.phone = "Invalid phone number";
      }
    }

    if (!formData.company.trim()) {
      newErrors.company = "Company name is required";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Initiating mail delivery");
    try {
      if (validate()) {
        console.log("Initiating mail delivery");
        setLoading(true);
        const data = {
          from: "assuranceIT <support@assuranceitcorp.com>",
          to: globalDataValues.leadRecipientEmail,
          subject: "New Inquiry from " + formData.name,
          template:"asit-leademail",
        };
        data['h:X-Mailgun-Variables'] = JSON.stringify({
          company: formData.company,
          email: formData.email,
          message: formData.message,
          name: formData.name,
          phone: formData.countryCode + formData.phone,
          submissionSource: "website_contact_modal",
          timestamp: new Date().toISOString()
        });
        data["h:Reply-To"] = formData.email;

        const response =  await mg.messages.create(globalDataValues.mailgun.domain, data);
        setLoading(false);
        setFormSubmitted(true);
        setShowFormMessage(true);
        setSuccessMessage("Message sent successfully!");
        
        // Reset form data
        setFormData({
          name: "",
          email: "",
          phone: "",
          countryCode: "+1",
          company: "",
          message: "",
        });

        // Send received response email
        const receivedResponseData = {
          from: globalDataValues.leadFromEmail,
          to: formData.email,
          subject: "We've Received Your Message - " + globalDataValues.orgNameForEmail,
          text: "Hi " + formData.name + ",\n\nWe've received your message and will get back to you shortly.\n\nThanks,\n" + globalDataValues.orgNameForEmail,
          template: "asit-thanksforreaching",
        };
        
        receivedResponseData['h:X-Mailgun-Variables'] = JSON.stringify({
          customerName: formData.name,
          respondantName: globalDataValues.respondantName,
          dateofSubmission: new Date().toLocaleDateString(),
          requestMessage: formData.message,
          orgNameForEmail: globalDataValues.orgNameForEmail,
          contactEmail: globalDataValues.contactEmail
        });
        receivedResponseData["h:Reply-To"] = globalDataValues.leadFromEmail;

        const receivedResponse = await mg.messages.create("notifications.cornershops.in", receivedResponseData);

        // Close modal after a short delay to show success message
        setTimeout(() => {
          closeModal();
        }, 1000);
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage(err.response && err.response.data.message ? err.response.data.message : err.message);
    }
  };

  useEffect(() => {
    if (inputref.current) {
      inputref.current.focus();
    }
  }, [inputref]);

  return (
    <>
      <ContactModalWrapper>
        <ContactModalContainer>
          <ContactModalHeader>
            <h2>Send us a message</h2>
            <span className="close-btn" onClick={closeModal}>
              <img src={CloseIcon} alt="Close" />
            </span>
          </ContactModalHeader>
          <ContactModalBody id="contactForm" onSubmit={handleSubmit}>
            <FormFields>
              <Input label={"Name"} id={"name"} type={"text"} name={"name"} value={formData.name} placeholder={"Enter your Name"} inputref={inputref} onChange={handleInputChange} isRequired={true} error={errors.name ?? undefined} />

              <Input label={"Email"} id={"email"} type={"email"} name={"email"} value={formData.email} placeholder={"Enter your Email"} onChange={handleInputChange} isRequired={true} error={errors.email ?? undefined} />

              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ flex: '3' }}>
                  <Select
                    label={"Country"}
                    id={"countryCode"}
                    type={"dropdown"}
                    name={"countryCode"}
                    value={formData.countryCode}
                    onChange={handleInputChange}
                    isRequired={true}
                    options={countryCodeOptions}
                    error={errors.countryCode ?? undefined}
                  />
                </div>
                <div style={{ flex: '7' }}>
                  <Input 
                    label={"Phone Number"} 
                    id={"phone"} 
                    type={"tel"} 
                    name={"phone"} 
                    value={formData.phone} 
                    placeholder={"Enter your Phone Number"} 
                    onChange={handleInputChange} 
                    isRequired={true} 
                    error={errors.phone ?? undefined} 
                  />
                </div>
              </div>

              <Input label={"Company"} id={"company"} type={"text"} name={"company"} value={formData.subject} placeholder={"Enter your company name"} onChange={handleInputChange} isRequired={true} error={errors.company ?? undefined} />

              <TextArea label={"Message"} id={"message"} name={"message"} value={formData.message} rows={4} placeholder={"Enter your message..."} onChange={handleInputChange} isRequired={true} error={errors.message ?? undefined} />
            </FormFields>
          </ContactModalBody>
          <ContactModalFooter>
            <Button type={"submit"} variant={"primary"} text={"Send Message"} form={"contactForm"} />
          </ContactModalFooter>
        </ContactModalContainer>
      </ContactModalWrapper>
    </>
  );
};

export default ContactModal;
