import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import UpArrowImg from './assets/icons/arrow-right.svg';
import emailIcon from './assets/icons/email.svg';
import { FloatingContactBtn } from "./components/globalStyles";
import ContactModal from "./modules/contact-modal/contact-modal";

const Navbar = lazy(() => import('./components/navbar/navbar'));
const Homepage = lazy(() => import('./pages/home/home'));
const About = lazy(() => import('./pages/about/about'));
const Services = lazy(() => import('./pages/services/services'));
const Contact = lazy(() => import('./pages/contact/contact'));
const ErrorPage = lazy(() => import('./pages/page404/page404'));

function AppContent() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const handleClick = () => {
    console.log(openContactModal);
    setOpenContactModal(true);
    console.log(openContactModal);
  }

  const closeModal = () => {
    setOpenContactModal(false);
  }

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {location.pathname !== '/contact' && (
        <FloatingContactBtn onClick={handleClick}>
          <img src={emailIcon} alt="Contact Us" />
        </FloatingContactBtn>
      )}
      {openContactModal && <ContactModal openModal={openContactModal} closeModal={closeModal} />}
      <span className={`go-to-top ${isScrolled ? 'show' : ''}`} title="Go to top" onClick={() => window.scrollTo(0, 0)} aria-label="Go to top">
        <img src={UpArrowImg} alt="Go to top" />
      </span>
    </>
  );
}

function App() {
  return (
    <Suspense fallback={<></>}>
      <Router>
        <AppContent />
      </Router>
    </Suspense>
  );
}

export default App;
