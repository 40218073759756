import React from "react";
import { SelectEle, SelectLabel } from "./select-styles";
import { FormError, FormField } from "../globalStyles";

const Select = ({ label, id, name, value, onChange, error, isRequired, options, children, style }) => {
  return (
    <FormField>
      <SelectLabel htmlFor={id}>
        {label}
        {isRequired && <span className="required">*</span>}
      </SelectLabel>
      <SelectEle id={id} name={name} value={value} onChange={onChange} style={style}>
        {options ? (
          <>
            <option value="">Select an option</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </>
        ) : (
          children
        )}
      </SelectEle>
      {error && <FormError>{error}</FormError>}
    </FormField>
  );
};

export default Select; 