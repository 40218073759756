import styled from "styled-components";

export const themeColorOne = "#0d2a69";
export const themeColorTwo = "#0799dd";
export const whiteColor = "#fff";

export const Title = "assuranceIT";

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media screen and (max-width: 1366px) {
    max-width: 90%;
  }
  @media screen and (min-width: 1367px) {
    max-width: 1200px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 80%;
  }
`;

export const Section = styled.section`
  padding: 80px 0;
  position: relative;
  &.skewed {
    padding: 160px 0;
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  }
  @media screen and (max-width: 767px) {
    padding: 60px 0;
    &.skewed {
      padding: 60px 0;
      clip-path: unset;
    }
  }
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: "Mulish", sans-serif;
    font-size: 2.25rem;
    font-weight: 700;
    color: ${themeColorOne};
    text-shadow: 3px 3px rgb(7 153 221 / 15%);
    padding-bottom: 18px;
    margin-bottom: 54px;
    position: relative;
    &::after {
      content: "";
      width: 30%;
      height: 4px;
      background-color: ${themeColorOne};
      border-radius: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      @media screen and (max-width: 767px) {
        height: 3px;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
      padding-bottom: 12px;
      margin-bottom: 24px;
    }
  }
  &.center-aligned {
    align-items: center;
    h2::after {
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 767px) {
      align-items: flex-start;
      h2::after {
        left: 0;
        transform: translateX(0);
      }
    }
  }
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormError = styled.span`
  display: inline-block;
  font-size: 0.85rem;
  color: rgb(220 53 69);
  margin: 4px 0 0;
`;

export const FloatingContactBtn = styled.span`
  background: linear-gradient(to bottom, ${themeColorOne}, ${themeColorTwo});
  box-shadow: -6px 6px 12px rgb(0 0 0 / 0.15);
  border-radius: 20px 20px 0px 20px;
  padding: 16px;
  color: ${whiteColor};
  /* writing-mode: vertical-rl;
  text-orientation: upright; */
  cursor: pointer;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 200;
  margin-right: 10px;

  @media screen and (max-width: 767px) {
    padding: 10px;
    margin-right:5px;
  }

  @media screen and (min-width: 991px) and (max-width: 1366px) {
      padding: 12px;
      margin-right:7px;
    }
`;
