import styled from "styled-components";
import { themeColorOne, whiteColor } from "../globalStyles";

export const InputLabel = styled.label`
  display: block;
  font-size: 0.95rem;
  margin: 0 0 6px;
  .required {
    color: red;
    display: inline-block;
    margin: 0 0 0 2px;
  }
`;

export const InputEle = styled.input`
  background-color: ${whiteColor};
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 16px 24px;
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
  outline: none;
  ::placeholder {
    opacity: 0.85;
  }
  &:focus {
    border-color: ${themeColorOne};
  }
`;
