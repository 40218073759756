import styled from "styled-components";
import { themeColorOne, whiteColor } from "../globalStyles";

export const SelectLabel = styled.label`
  display: block;
  font-size: 0.95rem;
  margin: 0 0 6px;
  .required {
    color: red;
    display: inline-block;
    margin: 0 0 0 2px;
  }
`;

export const SelectEle = styled.select`
  background-color: ${whiteColor};
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 16px 24px;
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
  outline: none;
  width: 100%;
  cursor: pointer;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  
  &:focus {
    border-color: ${themeColorOne};
  }
  
  option {
    padding: 8px 16px;
  }
`; 