import React from "react";
import { InputEle, InputLabel } from "./input-styles";
import { FormError, FormField } from "../globalStyles";

const Input = ({ label, id, name, type, value, placeholder, inputref, onChange, error, isRequired }) => {
  return (
    <FormField>
      <InputLabel htmlFor={id}>
        {label}
        {isRequired && <span className="required">*</span>}
      </InputLabel>
      <InputEle type={type} id={id} name={name} value={value} placeholder={placeholder} ref={inputref} onChange={onChange} />
      {error && <FormError>{error}</FormError>}
    </FormField>
  );
};

export default Input;
