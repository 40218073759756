import serviceImage from "./../assets/images/image-2.jpg";
import aboutImage from "./../assets/images/image-2.jpg";
import userImage from "./../assets/images/user-1.jpg";

//Icons for services cards
import cloudDevIcon from "./../assets/icons/servicecards/cloud-service.png";
import appDevIcon from "./../assets/icons/servicecards/app-development.png";
import dataAnalyticsIcon from "./../assets/icons/servicecards/data-analytics.png";
import staffAugmentationIcon from "./../assets/icons/servicecards/staff-augmentation.png";

//Images for service cards
import cloudEngineeringImage from "./../assets/images/services/CloudEngineering.png";
import DataEngineeringAnalyticsImage from "./../assets/images/services/DataEngineeringAnalytics.png";
import RapidApplicationDevelopmentImage from "./../assets/images/services/RapidApplicationDevelopment.png";
import StaffAugmentationImage from "./../assets/images/services/StaffAugmentation.png";

//Images for about page
import ourCultureImage from "./../assets/images/about/ourculture.jpg";
import ourStrengthImage from "./../assets/images/about/ourstrength.jpg";

//Icons for testimonial footer
import clientIconMen from "./../assets/icons/testimonialcards/client-avatar-Man.png";
import clientIconWoman from "./../assets/icons/testimonialcards/client-avatar-Woman.png";

export const globalDataValues = {
  orgName: "assuranceIT",
  orgNameForEmail: "Assurance IT Services",
  contactEmail: "info@assuranceitcorp.com",
  leadRecipientEmail: "assurance.durga@gmail.com,info@assuranceitcorp.com,accounts@cornershops.in",
  leadFromEmail: "assuranceIT <info@assuranceitcorp.com>",
  mailgun: {
    domain: "notifications.cornershops.in",
    username: "api",
    key: "60bcfd8958a4d6efda2daf887bf31f48-6df690bb-c9ee520b"
  }
};

export const menuItems = [
  {
    title: "About Us",
    redirectTo: "about",
    subMenu: [
      {
        title: "Our Culture",
        redirectTo: "about#ourCulture",
      },
      {
        title: "Our Strength",
        redirectTo: "about#ourStrength",
      },
      {
        title: "Our Team",
        redirectTo: "about#ourTeam",
      },
    ],
  },
  {
    title: "Our Expertise",
    redirectTo: "services",
    subMenu: [
      {
        title: "Cloud Engineering",
        redirectTo: "services#cloudEngineering",
      },
      {
        title: "Data Engineering & Analytics",
        redirectTo: "services#dataEngineering&Analytics",
      },
      {
        title: "Rapid App development",
        redirectTo: "services#rapidApplicationDevelopment",
      },
      {
        title: "Staff Augmentation",
        redirectTo: "services#staffAugmentation",
      },
    ],
  },
  // {
  //   title: "Our Clients",
  //   redirectTo: "/#clients",
  // },
  // {
  //   title: "Careers",
  //   redirectTo: "careers",
  // },
  {
    title: "Contact Us",
    redirectTo: "contact",
  },
];

export const about = [
  {
    title: "Our Culture",
    image: ourCultureImage,
    description: [
      "At " + globalDataValues.orgName + ", our positive culture is the cornerstone of our success. It attracts top talent who choose to make us their long-term home, not just a stepping-stone. Our recruitment efforts focus on retaining the best talent across the nation, fostering loyalty and commitment.",
      "We prioritize employee wellness - physically, mentally, and emotionally - creating a supportive environment where our team thrives. Through collaboration, social interaction, and open communication, we empower employees to feel their best and contribute boldly.",
      "This positive culture not only drives growth but also strengthens our competitive edge, ensuring we remain a destination for top talent.",
    ],
  },
  {
    title: "Our Strength",
    image: ourStrengthImage,
    description: [
      "At " + globalDataValues.orgName + ", our greatest strength is our people. They're the heart and soul of everything we do. With their diverse skills, passion, and relentless drive, they tackle the toughest challenges, turning visionary ideas into impactful results.",
      "We don't just hire talent; we cultivate thinkers, doers, and innovators who are committed to solving your most pressing problems. When you work with us, you're partnering with a team that isn't just technically skilled - they're personally invested in your success.",
    ],
  },
  {
    title: "Our Team",
    image: aboutImage,
    description: [
      "At AssuranceIT, our team is the cornerstone of our success. With over 15 years of experience, we've built a reputation for delivering unparalleled IT services and management consulting to a diverse range of clients across the USA. We pride ourselves on our ability to deliver 30% faster time-to-market, maintain a 98% client retention rate, and ensure 100% adherence to compliance standards - all thanks to the dedication and expertise of our talented professionals.",
      "Our team is a carefully curated group of industry experts, each bringing unique skills and perspectives to the table. Leveraging our proprietary database of highly qualified candidates with diverse technological expertise, we match the right talent with the right opportunities. This ensures that every team member seamlessly integrates with our clients organizational culture while delivering measurable results.",
      "We assign a dedicated Recruiting Manager to serve as a central point of contact for each client. This approach fosters collaboration, allowing us to understand your requirements deeply and provide tailored solutions. Our rigorous screening and placement process ensures that every professional we place is equipped to drive innovation and add immediate value to your business.",
      "With a shared commitment to excellence, the AssuranceIT team is here to help you navigate the complexities of today's business landscape, providing the expertise and support you need to achieve your goals. Together, let's build a partnership that empowers success.",
    ],
  },
];

export const services = [
  {
    title: "Cloud Engineering",
    icon: cloudDevIcon,
    image: cloudEngineeringImage,
    redirectionUrl: "services#cloudEngineering",
    description:
      "Cloud Engineering and Modernization empower businesses to overcome challenges like outdated infrastructure and complex migrations, enabling them to fully leverage cloud capabilities for growth and efficiency. AssuranceIT specializes in strategic assessments, seamless migrations, cloud-native architecture, and hybrid environment integration, delivering tangible outcomes such as increased agility, reduced IT costs, improved application performance, and faster time-to-market.",
    longDescription: {
      statementHeader: "Unlock the Full Potential of the Cloud",
      statementDetails:
        "In today's fast-paced digital landscape, businesses are increasingly relying on cloud technology to drive innovation and enhance operational efficiency. However, many organizations struggle with outdated infrastructure, complex migrations, and integration challenges. A significant 70% of enterprises report difficulties in fully leveraging cloud capabilities due to these barriers.",
      sectionStatement: "At assuranceIT, we specialize in Cloud Engineering & Modernization, providing you with the tools and expertise needed to transform your cloud strategy into a powerful engine for growth and efficiency.",
      subsections: [
        {
          sectionHeader: "Our Solutions:",
          sectionContentType: "list:number",
          sectionsContents: [
            {
              sectionContentHeader: "Strategic Cloud Assessments: ",
              sectionContentDetails: "We conduct in-depth assessments of your current infrastructure and cloud readiness, identifying areas for improvement and developing a tailored cloud strategy that aligns with your business goals.",
            },
            {
              sectionContentHeader: "Seamless Cloud Migration: ",
              sectionContentDetails: "Our team ensures a smooth migration to the cloud with minimal disruption to your operations utilizing proven methodologies and best practices to transition your applications and data securely and efficiently.",
            },
            {
              sectionContentHeader: "Cloud-Native Architecture Design:",
              sectionContentDetails: "We design cloud-native solutions that are optimized for performance, scalability, and resilience. Our architectures leverage microservices and serverless computing, enabling you to innovate faster and reduce operational costs.",
            },
            {
              sectionContentHeader: "Integration of Hybrid Environments:",
              sectionContentDetails: "Our expertise extends to hybrid cloud environments, ensuring seamless integration between on-premises and cloud resources. We help you create a unified ecosystem that maximizes flexibility and responsiveness.",
            },
          ],
        },
        {
          sectionHeader: "Tangible Outcomes for Your Business:",
          sectionContentType: "list:bullet",
          sectionsContents: [
            {
              sectionContentHeader: "",
              sectionContentDetails: "40% increase in operational agility, allowing them to respond quickly to changing market conditions and customer demands.",
            },
            {
              sectionContentHeader: "",
              sectionContentDetails: "Reduce IT costs by up to 30%, eliminating the need for costly hardware investments and minimizing maintenance expenses.",
            },
            {
              sectionContentHeader: "",
              sectionContentDetails: "Application performance improvements of up to 50%, leading to faster load times and improved user experiences.",
            },
            {
              sectionContentHeader: "",
              sectionContentDetails: "30% reducing time-to-market for new products and services by 30%, thus gaining a competitive edge.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Data Engineering & Analytics",
    icon: dataAnalyticsIcon,
    image: DataEngineeringAnalyticsImage,
    redirectionUrl: "services#dataEngineering&Analytics",
    description:
      "assuranceIT specializes in transforming data challenges into opportunities by offering solutions like data quality assurance, scalable architecture, seamless integration, and expert engineering talent, leading to improved operational efficiency, faster decision-making, and cost savings. Their expertise enables businesses to unlock actionable insights, enhance accessibility, and reduce project timelines, driving innovation and competitive advantage.",
    longDescription: {
      statementHeader: "Solutions to your Data problems begin here: Let's Transform Complexity into Clarity",
      statementDetails: "We specialize in addressing data quality, governance, scalability, and integration challenges head-on, transforming your data into a powerful resource for informed decision-making and competitive advantage.",
      sectionStatement: "",
      subsections: [
        {
          sectionHeader: "Data Quality Assurance",
          sectionContentType: "list:number",
          sectionsContents: [
            {
              sectionContentHeader: "Is your data lake a data swamp?",
              sectionContentDetails: "We help transform unreliable data into clear, actionable insights with automated cleansing, governance, and continuous monitoring. Expect up to 50% increased operational efficiency, 30% faster decision-making, and up to $10 million in annual savings.",
            },
          ],
        },
        {
          sectionHeader: "Scalable Data Architecture",
          sectionContentType: "list:number",
          sectionsContents: [
            {
              sectionContentHeader: "Struggling with outdated, rigid data systems?",
              sectionContentDetails: "We help transform unreliable data into clear, actionable insights with automated cleansing, governance, and continuous monitoring. Expect up to 50% increased operational efficiency, 30% faster decision-making, and up to $10 million in annual savings.",
            },
          ],
        },
        {
          sectionHeader: "Seamless Integration",
          sectionContentType: "list:number",
          sectionsContents: [
            {
              sectionContentHeader: "Struggling with disconnected data? ",
              sectionContentDetails: "We offer seamless integration solutions that unify your data sources for better insights and decision-making. Expect 50% more data accessibility, 40% improved reporting accuracy, and up to $2 million in savings. ",
            },
          ],
        },
        {
          sectionHeader: "•	Expert Data Engineering Talent",
          sectionContentType: "list:number",
          sectionsContents: [
            {
              sectionContentHeader: "Struggling to find skilled data engineers?",
              sectionContentDetails: "We provide access to top talent that accelerates your data initiatives. Reduce project timelines by 40%, save up to $1 million annually, and enhance data quality. With flexible staffing solutions and skill enhancement programs, gain a strategic advantage while meeting your data engineering needs.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Rapid Application Development",
    icon: appDevIcon,
    image: RapidApplicationDevelopmentImage,
    redirectionUrl: "services#rapidApplicationDevelopment",
    description:
      "assuranceIT accelerates innovation with Low-Code/No-Code platforms like Mendix and Power Apps, enabling rapid app development, workflow automation, and seamless system integration for up to 70% faster time-to-market. Their flexible service models—staff augmentation, team engagement, and results-driven delivery—ensure tailored solutions, measurable outcomes, and scalable expertise for diverse business needs.",
    longDescription: {
      statementHeader: "Low-Code/No-Code Platforms",
      statementDetails: "Accelerate Innovation with Rapid Application Development. Our Low-Code/No-Code (LCNC) services enable businesses to rapidly build and deploy apps with minimal coding, speeding up innovation, automating workflows, and responding to market demands.",
      sectionStatement: "",
      subsections: [
        {
          sectionHeader: "Our Expertise and Business Value:",
          sectionContentType: "list:bullet",
          sectionsContents: [
            {
              sectionContentHeader: "Platform Selection & Implementation:",
              sectionContentDetails: "With expertise in Mendix, OutSystems, and Microsoft Power Apps, we reduce development time by up to 70%, accelerating time to market.",
            },
            {
              sectionContentHeader: "Custom App Development:",
              sectionContentDetails: "Using drag-and-drop interfaces, we empower businesses to quickly create tailored apps, cutting costs with reusable components and quick iterations.",
            },
            {
              sectionContentHeader: "Workflow Automation: ",
              sectionContentDetails: "Automating repetitive processes boosts efficiency, enabling your teams to innovate without IT bottlenecks.",
            },
            {
              sectionContentHeader: "Integration Services",
              sectionContentDetails: "Seamlessly connecting LCNC apps with ERP, CRM, and other systems ensures smooth operations across platforms.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Staff Augmentation",
    icon: staffAugmentationIcon,
    image: StaffAugmentationImage,
    redirectionUrl: "services#staffAugmentation",
    description: "assuranceIT provides on-demand access to certified experts in Cloud, Data, Security, and Development, enabling businesses to scale teams quickly and efficiently. With minimal onboarding, we ensure rapid project initiation and seamless integration with your existing workflows",
    longDescription: {
      statementHeader: "Flexible Service Models",
      statementDetails: "We offer three flexible engagement models tailored to your business needs",
      sectionStatement: "",
      subsections: [
        {
          sectionHeader: "",
          sectionContentType: "list:number",
          sectionsContents: [
            {
              sectionContentHeader: "Professional Staff Augmentation: ",
              sectionContentDetails: "Access certified experts in Cloud, Data, Security, and Development, scaling your team as needed. Immediate availability with minimal onboarding, enabling rapid project initiation and flexible staffing.",
            },
            {
              sectionContentHeader: "Experienced Team Engagement: ",
              sectionContentDetails: "Full lifecycle project execution with tailored, cross-functional teams for end-to-end support. Agile project management and transparent reporting ensure timely delivery with high accountability for quality.",
            },
            {
              sectionContentHeader: "Result-Oriented Delivery: ",
              sectionContentDetails: "Every engagement is designed to deliver measurable business outcomes aligned with clear KPIs.Risk mitigation strategies and continuous quality assurance ensure tangible results and performance tracking that aligns with your goals.",
            },
          ],
        },
      ],
    },
  },
];

export const clients = [
  {
    name: "client 1",
    logo: require("./../assets/client-logos/sdf-radiant-heat.svg").default,
  },
  {
    name: "client 2",
    logo: require("./../assets/client-logos/sdf-radiant-heat.svg").default,
  },
  {
    name: "client 3",
    logo: require("./../assets/client-logos/sdf-radiant-heat.svg").default,
  },
  {
    name: "client 4",
    logo: require("./../assets/client-logos/southern-states.svg").default,
  },
  {
    name: "client 5",
    logo: require("./../assets/client-logos/sdf-radiant-heat.svg").default,
  },
  {
    name: "client 6",
    logo: require("./../assets/client-logos/olstykke.svg").default,
  },
  {
    name: "client 7",
    logo: require("./../assets/client-logos/netflix.svg").default,
  },
  {
    name: "client 8",
    logo: require("./../assets/client-logos/sdf-radiant-heat.svg").default,
  },
  {
    name: "client 9",
    logo: require("./../assets/client-logos/sdf-radiant-heat.svg").default,
  },
];

export const faqs = [
  {
    title: "Frequently Asked Question 1",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    title: "Frequently Asked Question 2",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    title: "Frequently Asked Question 3",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    title: "Frequently Asked Question 4",
    description: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    ],
  },
];

export const sectionDescriptions = {
  culture: {
    sectionHeading: "Our Culture = Positive Culture",
    shortDescription: [
      "At " + globalDataValues.orgName + ", our positive culture is the cornerstone of our success. It attracts top talent who choose to make us their long-term home, not just a stepping-stone. Our recruitment efforts focus on retaining the best talent across the nation, fostering loyalty and commitment.",
      "We prioritize employee wellness—physically, mentally, and emotionally—creating a supportive environment where our team thrives. Through collaboration, social interaction, and open communication, we empower employees to feel their best and contribute boldly.",
      "This positive culture not only drives growth but also strengthens our competitive edge, ensuring we remain a destination for top talent.",
    ],
    longDescription: [],
  },
  strength: {
    sectionHeading: "Our Strength",
    shortDescription: [
      "At " + globalDataValues.orgName + ", our positive culture is the cornerstone of our success. It attracts top talent who choose to make us their long-term home, not just a stepping-stone. Our recruitment efforts focus on retaining the best talent across the nation, fostering loyalty and commitment.",
      "We prioritize employee wellness—physically, mentally, and emotionally—creating a supportive environment where our team thrives. Through collaboration, social interaction, and open communication, we empower employees to feel their best and contribute boldly.",
      "This positive culture not only drives growth but also strengthens our competitive edge, ensuring we remain a destination for top talent.",
    ],
    longDescription: [],
  },
};

export const testimonials = [
  {
    title: "Navigating Data Engineering Challenges",
    testimony:
      "Partnering with assuranceIT was a game-changer for us. They helped us tackle complex data engineering challenges and streamlined our data pipeline, ensuring we could access accurate, real-time insights for better decision-making. Their expertise in data integration and quality assurance transformed our data architecture, allowing us to scale and innovate faster than we ever thought possible.",
    client: "John D",
    image: clientIconMen,
    role: "CTO",
    company: "Global Retailer",
  },
  {
    title: "Modernizing Legacy Applications",
    testimony:
      "We were stuck with outdated systems that were hindering our ability to scale. assuranceIT came in, not just to fix the problems but to completely modernize our legacy applications. They delivered solutions that integrated seamlessly with our new infrastructure, enabling us to optimize performance and reduce operational costs. The modernization was smooth, efficient, and exactly what we needed.",
    client: "Rachel S",
    image: clientIconWoman,
    role: "VP of IT",
    company: "Financial Services Company",
  },
  {
    title: "Filling Key Resource Gaps During Cloud Migration",
    testimony: "As we migrated to the cloud, we faced critical skill gaps that were slowing down the project. assuranceIT provided us with highly skilled engineers who not only filled those gaps but also brought in-depth knowledge to ensure a smooth and timely migration. Their support was invaluable, enabling us to move to the cloud without disruption and with complete confidence.",
    client: "Michael P",
    image: clientIconMen,
    role: "Head of Cloud Infrastructure",
    company: "Healthcare Organization",
  },
  {
    title: "Data Engineering and Cloud Migration Synergy",
    testimony:
      "We faced significant challenges with integrating our data sources while migrating to the cloud. assuranceIT expertly navigated both complexities, providing us with not just the technical resources we needed, but a seamless strategy to bring everything together. The team's ability to modernize our data engineering processes while ensuring cloud readiness was a critical success factor in our migration.",
    client: "Sandra M",
    image: clientIconWoman,
    role: "CIO",
    company: "E-Commerce Leader",
  },
];

export const team = [
  {
    name: "Betty Cooper",
    image: userImage,
    designation: "Founder/CEO",
    description: "assuranceIT specializes in transforming data challenges into opportunities by offering solutions.",
    socialMedia: [
      {
        facebook: "https://www.facebook.com",
        linkedIn: "https://www.linkedin.com",
        instagram: "https://www.instagram.com",
      },
    ],
  },
  {
    name: "Bonnie Bennette",
    image: userImage,
    designation: "Business Head",
    description: "assuranceIT specializes in transforming data challenges into opportunities by offering solutions.",
    socialMedia: [
      {
        facebook: "https://www.facebook.com",
        linkedIn: "https://www.linkedin.com",
        instagram: "https://www.instagram.com",
      },
    ],
  },
  {
    name: "Clarke Griffin",
    image: userImage,
    designation: "Marketing Head",
    description: "assuranceIT specializes in transforming data challenges into opportunities by offering solutions.",
    socialMedia: [
      {
        facebook: "https://www.facebook.com",
        linkedIn: "https://www.linkedin.com",
        instagram: "https://www.instagram.com",
      },
    ],
  },
  {
    name: "Max Mayfield",
    image: userImage,
    designation: "UI Developer",
    description: "assuranceIT specializes in transforming data challenges into opportunities by offering solutions.",
    socialMedia: [
      {
        facebook: "https://www.facebook.com",
        linkedIn: "https://www.linkedin.com",
        instagram: "https://www.instagram.com",
      },
    ],
  },
];

export const countryCodeOptions = [
  { value: "+1", label: "+1 (USA)" },
  { value: "+93", label: "+93 (Afghanistan)" },
  { value: "+355", label: "+355 (Albania)" },
  { value: "+213", label: "+213 (Algeria)" },
  { value: "+376", label: "+376 (Andorra)" },
  { value: "+244", label: "+244 (Angola)" },
  { value: "+54", label: "+54 (Argentina)" },
  { value: "+374", label: "+374 (Armenia)" },
  { value: "+61", label: "+61 (Australia)" },
  { value: "+43", label: "+43 (Austria)" },
  { value: "+994", label: "+994 (Azerbaijan)" },
  { value: "+973", label: "+973 (Bahrain)" },
  { value: "+880", label: "+880 (Bangladesh)" },
  { value: "+375", label: "+375 (Belarus)" },
  { value: "+32", label: "+32 (Belgium)" },
  { value: "+501", label: "+501 (Belize)" },
  { value: "+229", label: "+229 (Benin)" },
  { value: "+975", label: "+975 (Bhutan)" },
  { value: "+591", label: "+591 (Bolivia)" },
  { value: "+387", label: "+387 (Bosnia and Herzegovina)" },
  { value: "+267", label: "+267 (Botswana)" },
  { value: "+55", label: "+55 (Brazil)" },
  { value: "+673", label: "+673 (Brunei)" },
  { value: "+359", label: "+359 (Bulgaria)" },
  { value: "+226", label: "+226 (Burkina Faso)" },
  { value: "+257", label: "+257 (Burundi)" },
  { value: "+855", label: "+855 (Cambodia)" },
  { value: "+237", label: "+237 (Cameroon)" },
  { value: "+1", label: "+1 (Canada)" },
  { value: "+236", label: "+236 (Central African Republic)" },
  { value: "+235", label: "+235 (Chad)" },
  { value: "+56", label: "+56 (Chile)" },
  { value: "+86", label: "+86 (China)" },
  { value: "+57", label: "+57 (Colombia)" },
  { value: "+269", label: "+269 (Comoros)" },
  { value: "+242", label: "+242 (Congo)" },
  { value: "+506", label: "+506 (Costa Rica)" },
  { value: "+385", label: "+385 (Croatia)" },
  { value: "+53", label: "+53 (Cuba)" },
  { value: "+357", label: "+357 (Cyprus)" },
  { value: "+420", label: "+420 (Czech Republic)" },
  { value: "+45", label: "+45 (Denmark)" },
  { value: "+253", label: "+253 (Djibouti)" },
  { value: "+593", label: "+593 (Ecuador)" },
  { value: "+20", label: "+20 (Egypt)" },
  { value: "+503", label: "+503 (El Salvador)" },
  { value: "+240", label: "+240 (Equatorial Guinea)" },
  { value: "+291", label: "+291 (Eritrea)" },
  { value: "+372", label: "+372 (Estonia)" },
  { value: "+251", label: "+251 (Ethiopia)" },
  { value: "+679", label: "+679 (Fiji)" },
  { value: "+358", label: "+358 (Finland)" },
  { value: "+33", label: "+33 (France)" },
  { value: "+241", label: "+241 (Gabon)" },
  { value: "+220", label: "+220 (Gambia)" },
  { value: "+995", label: "+995 (Georgia)" },
  { value: "+49", label: "+49 (Germany)" },
  { value: "+233", label: "+233 (Ghana)" },
  { value: "+30", label: "+30 (Greece)" },
  { value: "+502", label: "+502 (Guatemala)" },
  { value: "+224", label: "+224 (Guinea)" },
  { value: "+245", label: "+245 (Guinea-Bissau)" },
  { value: "+592", label: "+592 (Guyana)" },
  { value: "+509", label: "+509 (Haiti)" },
  { value: "+504", label: "+504 (Honduras)" },
  { value: "+852", label: "+852 (Hong Kong)" },
  { value: "+36", label: "+36 (Hungary)" },
  { value: "+354", label: "+354 (Iceland)" },
  { value: "+91", label: "+91 (India)" },
  { value: "+62", label: "+62 (Indonesia)" },
  { value: "+98", label: "+98 (Iran)" },
  { value: "+964", label: "+964 (Iraq)" },
  { value: "+353", label: "+353 (Ireland)" },
  { value: "+972", label: "+972 (Israel)" },
  { value: "+39", label: "+39 (Italy)" },
  { value: "+225", label: "+225 (Ivory Coast)" },
  { value: "+81", label: "+81 (Japan)" },
  { value: "+962", label: "+962 (Jordan)" },
  { value: "+7", label: "+7 (Kazakhstan)" },
  { value: "+254", label: "+254 (Kenya)" },
  { value: "+686", label: "+686 (Kiribati)" },
  { value: "+82", label: "+82 (South Korea)" },
  { value: "+965", label: "+965 (Kuwait)" },
  { value: "+996", label: "+996 (Kyrgyzstan)" },
  { value: "+856", label: "+856 (Laos)" },
  { value: "+371", label: "+371 (Latvia)" },
  { value: "+961", label: "+961 (Lebanon)" },
  { value: "+266", label: "+266 (Lesotho)" },
  { value: "+231", label: "+231 (Liberia)" },
  { value: "+218", label: "+218 (Libya)" },
  { value: "+423", label: "+423 (Liechtenstein)" },
  { value: "+370", label: "+370 (Lithuania)" },
  { value: "+352", label: "+352 (Luxembourg)" },
  { value: "+853", label: "+853 (Macau)" },
  { value: "+389", label: "+389 (Macedonia)" },
  { value: "+261", label: "+261 (Madagascar)" },
  { value: "+265", label: "+265 (Malawi)" },
  { value: "+60", label: "+60 (Malaysia)" },
  { value: "+960", label: "+960 (Maldives)" },
  { value: "+223", label: "+223 (Mali)" },
  { value: "+356", label: "+356 (Malta)" },
  { value: "+692", label: "+692 (Marshall Islands)" },
  { value: "+222", label: "+222 (Mauritania)" },
  { value: "+230", label: "+230 (Mauritius)" },
  { value: "+52", label: "+52 (Mexico)" },
  { value: "+373", label: "+373 (Moldova)" },
  { value: "+377", label: "+377 (Monaco)" },
  { value: "+976", label: "+976 (Mongolia)" },
  { value: "+382", label: "+382 (Montenegro)" },
  { value: "+212", label: "+212 (Morocco)" },
  { value: "+258", label: "+258 (Mozambique)" },
  { value: "+95", label: "+95 (Myanmar)" },
  { value: "+264", label: "+264 (Namibia)" },
  { value: "+674", label: "+674 (Nauru)" },
  { value: "+977", label: "+977 (Nepal)" },
  { value: "+31", label: "+31 (Netherlands)" },
  { value: "+64", label: "+64 (New Zealand)" },
  { value: "+505", label: "+505 (Nicaragua)" },
  { value: "+227", label: "+227 (Niger)" },
  { value: "+234", label: "+234 (Nigeria)" },
  { value: "+850", label: "+850 (North Korea)" },
  { value: "+47", label: "+47 (Norway)" },
  { value: "+968", label: "+968 (Oman)" },
  { value: "+92", label: "+92 (Pakistan)" },
  { value: "+680", label: "+680 (Palau)" },
  { value: "+970", label: "+970 (Palestine)" },
  { value: "+507", label: "+507 (Panama)" },
  { value: "+675", label: "+675 (Papua New Guinea)" },
  { value: "+595", label: "+595 (Paraguay)" },
  { value: "+51", label: "+51 (Peru)" },
  { value: "+63", label: "+63 (Philippines)" },
  { value: "+48", label: "+48 (Poland)" },
  { value: "+351", label: "+351 (Portugal)" },
  { value: "+974", label: "+974 (Qatar)" },
  { value: "+40", label: "+40 (Romania)" },
  { value: "+7", label: "+7 (Russia)" },
  { value: "+250", label: "+250 (Rwanda)" },
  { value: "+685", label: "+685 (Samoa)" },
  { value: "+378", label: "+378 (San Marino)" },
  { value: "+966", label: "+966 (Saudi Arabia)" },
  { value: "+221", label: "+221 (Senegal)" },
  { value: "+381", label: "+381 (Serbia)" },
  { value: "+248", label: "+248 (Seychelles)" },
  { value: "+232", label: "+232 (Sierra Leone)" },
  { value: "+65", label: "+65 (Singapore)" },
  { value: "+421", label: "+421 (Slovakia)" },
  { value: "+386", label: "+386 (Slovenia)" },
  { value: "+677", label: "+677 (Solomon Islands)" },
  { value: "+252", label: "+252 (Somalia)" },
  { value: "+27", label: "+27 (South Africa)" },
  { value: "+211", label: "+211 (South Sudan)" },
  { value: "+34", label: "+34 (Spain)" },
  { value: "+94", label: "+94 (Sri Lanka)" },
  { value: "+249", label: "+249 (Sudan)" },
  { value: "+597", label: "+597 (Suriname)" },
  { value: "+268", label: "+268 (Swaziland)" },
  { value: "+46", label: "+46 (Sweden)" },
  { value: "+41", label: "+41 (Switzerland)" },
  { value: "+963", label: "+963 (Syria)" },
  { value: "+886", label: "+886 (Taiwan)" },
  { value: "+992", label: "+992 (Tajikistan)" },
  { value: "+255", label: "+255 (Tanzania)" },
  { value: "+66", label: "+66 (Thailand)" },
  { value: "+228", label: "+228 (Togo)" },
  { value: "+676", label: "+676 (Tonga)" },
  { value: "+216", label: "+216 (Tunisia)" },
  { value: "+90", label: "+90 (Turkey)" },
  { value: "+993", label: "+993 (Turkmenistan)" },
  { value: "+256", label: "+256 (Uganda)" },
  { value: "+380", label: "+380 (Ukraine)" },
  { value: "+971", label: "+971 (United Arab Emirates)" },
  { value: "+44", label: "+44 (United Kingdom)" },
  { value: "+598", label: "+598 (Uruguay)" },
  { value: "+998", label: "+998 (Uzbekistan)" },
  { value: "+678", label: "+678 (Vanuatu)" },
  { value: "+379", label: "+379 (Vatican City)" },
  { value: "+58", label: "+58 (Venezuela)" },
  { value: "+84", label: "+84 (Vietnam)" },
  { value: "+967", label: "+967 (Yemen)" },
  { value: "+260", label: "+260 (Zambia)" },
  { value: "+263", label: "+263 (Zimbabwe)" }
];
