import styled from "styled-components";
import { whiteColor } from "../../components/globalStyles";

export const ContactModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
`;

export const ContactModalContainer = styled.div`
  width: 30%;
  max-height: 75%;
  background: ${whiteColor};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 991px) {
    width: 75%;
  }
`;

export const ContactModalHeader = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #f7f7f7;
  display: flex;
  align-items: center;
  gap: 24px;
  h2 {
    flex: 1%;
    font-size: 1.35rem;
  }
  .close-btn {
    width: 32px;
    height: 32px;
    background: #f7f7f7;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const ContactModalBody = styled.form`
  flex: 1;
  overflow: auto;
  padding: 24px;
  & > div {
    margin-bottom: 0;
  }
`;

export const ContactModalFooter = styled.div`
  border-top: 1px solid #f7f7f7;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
