import styled from "styled-components";
import { themeColorOne, whiteColor } from "../globalStyles";

export const TextAreaEle = styled.textarea`
  background-color: ${whiteColor};
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 16px 24px;
  font-family: "Mulish", sans-serif;
  font-size: 1rem;
  resize: none;
  outline: none;
  ::placeholder {
    opacity: 0.85;
  }
  &:focus {
    border-color: ${themeColorOne};
  }
`;
